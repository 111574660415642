import api from "@/api";
import { parseTime } from "@/utils";
import { ElMessageBox } from "element-plus";
import { Vue } from "vue-class-component";
import './index.scss'

export default class MineVoucher extends Vue {

  private voucherList: Array<any> = []
  private dataList: Array<any> = []

  public pageNum = 1
  public pageSize = 12
  public total = 0

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  getList(): void {
    Promise.all([
      api.couponList({}),
      api.couponGetList({pageNum: this.pageNum, pageSize: this.pageSize})
    ]).then(res => {
      this.voucherList = res[0].data
      this.dataList = res[1].data.list
      this.total = res[1].data.total
      this.pageNum = res[1].data.pageNum
      this.pageSize = res[1].data.pageSize
    })
  }

  render(): JSX.Element {
    return <div class="mine_voucher">
      <span class="mine_voucher-sum">共{this.voucherList.length}个代金券</span>
      <el-row gutter={40}>
        { this.voucherList.map(item => <el-col
          xs={24}
          sm={12}
          md={8}
          lg={6}
          xl={6}
        >
          <div class="mine_voucher-cell">
            <div
              class="mine_voucher-cell-bg"
              style={{
                backgroundImage: `url(${item.img})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: '100% 100%'
              }}
            >
              <span class="mine_voucher-cell-price">HK${item.amount}</span>
              <span class="mine_voucher-cell-name">{item.name}</span>
            </div>
            <div class="mine_voucher-cell-time">
              {parseTime(new Date(item.startTime).getTime(), '{y}.{m}.{d}')}-{parseTime(new Date(item.endTime).getTime(), '{y}.{m}.{d}')}
            </div>
          </div>
        </el-col>) }
      </el-row>
        <span class="mine_voucher-more">更多优惠券</span>
        <el-row  gutter={60}>
        {this.dataList.map(item => <el-col xs={24} sm={12} md={8} lg={8} xl={6}>
          <div class="mine_voucher-product">
            <img src={item.img} alt={item.name} />
            <div class="mine_voucher-product-right">
              <div class="mine_voucher-product-title">{item.name}</div>
              <div class="mine_voucher-product-bottom">
                <div>积分： <span>{item.point}</span></div>
                <div class="btn" onClick={() => this.exchange(item.id)}>
                  <span>立即兑换</span>
                  <i class="el-icon-arrow-right"/>
                </div>
              </div>
            </div>
          </div>
        </el-col>)}
      </el-row>
      <el-pagination
          class="mine_voucher-pagination"
          onCurrentChange={this.handleCurrentChange}
          currentPage={this.pageNum}
          pageSize={this.pageSize}
          layout="prev, pager, next, total"
          total={ this.total }
        />
    </div>
  }

  exchange(id: number): void {
    ElMessageBox.confirm('确定兑换该代金券？', '兑换代金券', {
      type: 'warning'
    }).then(() => {
      api.memberCouponAdd(id).then(() => {
        this.getList()
      })
    }).catch(() => false)
  }

  mounted(): void {
    this.getList()
  }
}
